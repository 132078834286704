import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "accordion",
      "style": {
        "position": "relative"
      }
    }}>{`Accordion`}</h1>
    <p>{`O `}<em parentName="p">{`accordion`}</em>{` é um componente clicável que revela um conteúdo associado oculto. É uma das formas de dar controle ao usuário do que ele quer ler e o que prefere ignorar.
Quando minimizado, diminui a rolagem da página e fornece uma visualização rápida do conteúdo da tela, permitindo que o usuário forme um rápido modelo mental das informações disponíveis na interface.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Não utilize o `}<em parentName="li">{`accordion`}</em>{` se a informação oculta for necessária e se o usuário precisar expandir muitos itens para encontrar a informação desejada. Neste caso, considere deixar todas as informações visíveis;`}</li>
      <li parentName="ul">{`Considere otimizar as páginas para impressão, de modo a apresentar todas as informações ocultas;`}</li>
      <li parentName="ul">{`Em tabelas, nas situações que a lista apresenta somente alguns itens expansíveis, os itens que não utilizam o `}<em parentName="li">{`accordion`}</em>{` devem apresentar o mesmo recuo dos demais. Quando o `}<em parentName="li">{`accordion`}</em>{` é aplicado em cards, não é necessário manter o mesmo alinhamento.`}</li>
    </ul>
    <p><strong parentName="p">{`Este componente ainda não foi implementado como um componente em React.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      